<template>
  <div>
    <Modal :options="{width: '50vw', close: true}" @close="$emit('close')">
      <div class="title">Detalle de incidencia</div>
      <div class="body">

        <div class="row form-group">
          <label for="tipo_incidencia" class="col-form-label col-sm-2">Tipo de incidencia</label>
          <div class="col-sm-10">
            <div class="form-control">
              {{ buscar_tipo(editar_incidencia.subtipo) }}
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
          <div class="col-sm-10">
            <div class="form-control">
              {{ editar_incidencia.descripcion }}
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label for="comentarios_finales" class="col-form-label col-sm-2">Comentarios finales</label>
          <div class="col-sm-10">
            <textarea v-model="editar_incidencia.comentarios_finales" name="comentarios_finales" id="comentarios_finales" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>

        <div class="row form-group">
          <label for="comentarios_finales" class="col-form-label col-sm-2">Documentos</label>
          <div class="col-sm-10">
            <div class="row mb-2">
              <div class="col-sm-12 text-right"><button class="btn btn-secondary" @click="modal_evidencia=true">Cargar documento</button></div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <table class="incidencia_documentos">
                  <thead>
                    <tr>
                      <th>Documento</th>
                      <th>Tipo</th>
                      <th>Peso</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(documento, index) in documentos">
                      <td>{{ documento.archivo.name }}</td>
                      <td>{{ buscar_tipo_valija(documento.tipo) }}</td>
                      <td>{{ $helper.bytesConverted(documento.archivo.size) }}</td>
                      <td class="text-center">
                        <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar incidencia" title="Elimitar incidencia" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-info mr-2" @click="guardar_incidencia">Guardar cambios</button>
            <button class="btn btn-success mr-2" @click="modal_resolver_incidencia=true">Resolver incidencia</button>
            <button class="btn btn-danger" @click="$emit('close')">Cerrar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal_resolver_incidencia" :options="{width: '40vw',type: 'danger'}">
      <div class="title">Resolver incidencia</div>
      <div class="body">
        <p class="text-center">Al resolver la incidencia no podrás volver a editarla <br/>¿Deseas continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-success mr-2" @click="resolver_incidencia">Resolver incidencia</button>
            <button class="btn btn-danger" @click="modal_resolver_incidencia=false">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Evidencia v-if="modal_evidencia" @close="modal_evidencia=false" @file="agregar_documento" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import Evidencia from './Evidencia'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiValija from '@/apps/formalizacion/api/v2/valija'

  export default {
    components: {
      Modal, Evidencia
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,incidencia: {
        type: Object
        ,default() {
          return {
            subtipo: null
            ,descripcion: null
            ,evidencias: []
          }
        }
      }
      ,catalogo: {
        type: Array
        ,default() {
          return []
        }
      }
    }
    ,data() {
      return {
        editar_incidencia: {
          id: null,
          subtipo: null,
          comentarios_finales: null
        }
        ,modal_resolver_incidencia: false
        ,documentos: []
        ,modal_evidencia: false
        ,catalogo_valija: {
          opciones: []
        }
      }
    }
    ,mounted() {
      this.editar_incidencia = this.$helper.clone(this.incidencia);
      this.incidencia.evidencias.forEach(evidencia => {
        this.documentos.push({
          id: evidencia.id
          ,archivo: {
            name: evidencia.nombre_original
            ,size: evidencia.peso
          }
          ,tipo: evidencia.tag
        });
      })

      this.obtener_catalogo();
    }
    ,methods: {
      async obtener_catalogo() {
        try {
          this.catalogo_valija = await this.$helper.cache_store('valija.tipos_archivos',async () => {
            return (await apiValija.obtener_catalogo('tipos_archivos')).data;
          })
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_tipo(subtipo) {
        let nombre;

        this.catalogo.forEach(opcion => {
          if (opcion.valor == subtipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,async guardar_incidencia() {
        try {
          let res = (await apiSolicitud.incidencias_editar(this.solicitud.id, {
            incidencia_id: this.editar_incidencia.id
            ,comentarios_finales: this.editar_incidencia.comentarios_finales
          })).data;
          this.$log.info('res', res);

          if (this.documentos.length > 0) {
            let payload = new FormData();

            let id = 0;
            this.documentos.forEach(documento => {
              if (!documento.id) {
                payload.append('archivos['+id+'][archivo]',documento.archivo);
                payload.append('archivos['+id+'][tipo]',documento.tipo);
                id++;
              }
            });

            if (id > 0) {
              res = (await apiSolicitud.incidencias_evidencias(this.solicitud.id, this.incidencia.id, payload)).data;

              this.documentos = []
              res.evidencias.forEach(evidencia => {
                this.documentos.push({
                  id: evidencia.id,
                  archivo: {
                    name: evidencia.nombre_original
                    ,size: evidencia.peso
                  }
                  ,tipo: evidencia.tag
                });
              })
              this.$log.info('res', res);
            }
          }

          this.$emit('update')
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async resolver_incidencia() {
        try {
          if (!this.editar_incidencia.comentarios_finales || this.editar_incidencia.comentarios_finales.length < 5)
            return this.$helper.showMessage('Error','Debes definir tus comentarios finales','error','alert');

          if (this.documentos.length == 0)
            return this.$helper.showMessage('Error','Debes definir al menos un documento como evidencia','error','alert');

          this.guardar_incidencia();

          let res = (await apiSolicitud.incidencias_editar(this.solicitud.id, {
            incidencia_id: this.editar_incidencia.id
            ,comentarios_finales: this.editar_incidencia.comentarios_finales
            ,resolucion: true
          })).data;
          this.$log.info('res', res);

          this.$emit('update');
          this.$emit('close');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,agregar_documento(documento) {
        this.documentos.push(documento);

        this.modal_evidencia = false;
      }
      ,buscar_extension(nombre) {
        let partes = nombre.split('.');

        return partes[partes.length-1];
      }
      ,buscar_tipo_valija(tipo) {
        let nombre;

        this.catalogo_valija.opciones.forEach(opcion => {
          if (opcion.valor == tipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #comentarios_finales {
    max-height: 120px;
    resize: none;
  }

  .incidencia_documentos {
    width: 100%;

    thead {
      tr {
        background-color: #4C4C4C;
        color: #fff;

        th {
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #4C4C4C;

        td {
          padding: 5px 10px;

          img {
            width: 20px;
            cursor: pointer;
          }
        }
      }

      tr:last-child {
        border-bottom: 0px !important;
      }
    }
  }
</style>